/*
Template Name: Admin Template
Author: Maskoid Technologies
File: scss
*/

@import '~hopcms-ui/src/assets/scss/variables.scss';

// Import Bootstrap source files (Required file)
@import '~bootstrap/scss/bootstrap.scss';

//Import HopCMS Bootstrap Overides
@import '~hopcms-ui/src/assets/scss/custom.scss';

.MuiTableCell-root.MuiTableCell-body.special{
    border: 0;
    padding: 0 !important;
}